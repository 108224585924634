<template>
  <div class="box-container">
    <v-row
      v-if="!item.id && loading"
      justify="center"
      align="center"
      class="justify-center align-center"
    >
      <v-col
        class="text-center text--center justify-center align-center"
        cols="12"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          :size="100"
        />
      </v-col>
    </v-row>
    <div
      v-if="item.id"
      class="d-flex justify-space-between flex-wrap mb-5"
    >
      <div class="d-flex align-center ">
        <v-avatar
          tile
          size="25"
          class="me-3"
        >
          <img
            src="@/assets/images/icons/bag_filled.svg"
            alt=""
          >
        </v-avatar>
        <h2 class="mb-0">
          {{ $t("Commande") }} #{{ item.id || $route.params.id }}
        </h2>
        <v-spacer />
      </div>
      <div class="d-flex ">
        <v-btn
          v-if="item.id && item.status === $store.state.statuses.orders.CLOSED"
          outlined
          class=" text-capitalize font-600"
          color="default"
          target="_blank"
          :href="`${ baseUrl }/order/${item.id}`"
        >
          <v-icon>mdi-printer</v-icon>{{ $t("Télécharger la facture") }}
        </v-btn>
      </div>
    </div>

    <v-row v-if="item.id">
      <v-col cols="12">
        <base-card>
          <div class="px-6 py-14">
            <div class="d-flex align-center">
              <div class="p-relative">
                <v-avatar
                  class="order-details-custom-done"
                  size="22"
                  color="grey lighten-2"
                >
                  <img
                    v-svg-inline
                    class="w-12 icon secondary--text text--darken-1 mr-0"
                    src="@/assets/images/icons/done.svg"
                    alt=""
                  >
                </v-avatar>
                <v-avatar
                  size="64"
                  color="primary"
                >
                  <img
                    v-svg-inline
                    class="w-32 icon secondary--text text--darken-1 mr-0"
                    src="@/assets/images/icons/package-box.svg"
                    alt=""
                  >
                </v-avatar>
              </div>
              <v-sheet
                :color="item.status >= $store.state.statuses.orders.VALIDATED?'primary':'grey lighten-2'"
                elevation="0"
                height="4"
                class="flex-grow-1"
              />
              <div class="p-relative">
                <v-avatar
                  v-if="item.status >= $store.state.statuses.orders.VALIDATED"
                  class="order-details-custom-done"
                  size="22"
                  color="grey lighten-2"
                >
                  <img
                    v-svg-inline
                    class="w-12 icon secondary--text text--darken-1 mr-0"
                    src="@/assets/images/icons/done.svg"
                    alt=""
                  >
                </v-avatar>
                <v-avatar
                  size="64"
                  :color="item.status >= $store.state.statuses.orders.VALIDATED?'primary':'grey lighten-2'"
                >
                  <img
                    v-svg-inline
                    class="w-32 icon white--text mr-0"
                    :class="item.status >= $store.state.statuses.orders.VALIDATED?'white--text':'primary--text'"
                    src="@/assets/images/icons/truck-1.svg"
                    alt=""
                  >
                </v-avatar>
              </div>
              <v-sheet
                :color="item.status >= $store.state.statuses.orders.CLOSED?'primary':'grey lighten-2'"
                elevation="0"
                height="4"
                class="flex-grow-1"
              />
              <div class="p-relative">
                <v-avatar
                  v-if="item.status >= $store.state.statuses.orders.CLOSED"
                  class="order-details-custom-done"
                  size="22"
                  color="grey lighten-2"
                >
                  <img
                    v-svg-inline

                    class="w-12 icon secondary--text text--darken-1 mr-0"
                    src="@/assets/images/icons/done.svg"
                    alt=""
                  >
                </v-avatar>
                <v-avatar
                  size="64"
                  class=""
                  :color="item.status >= $store.state.statuses.orders.CLOSED?'primary':'grey lighten-2'"
                >
                  <img
                    v-svg-inline
                    :class="{'white--text': item.status >= $store.state.statuses.orders.CLOSED, 'primary--text': item.status !== $store.state.statuses.orders.CLOSED}"
                    class="w-32 icon white--text mr-0"
                    src="@/assets/images/icons/delivery-done.svg"
                    alt=""
                  >
                </v-avatar>
              </div>
            </div>
            <div class=" mt-10">
              <div
                v-if="item.id && item.status === $store.state.statuses.orders.CANCEL"
                class="py-2 px-5 red--text red lighten-5 text-center text-wrap rounded-pill"
              >
                <p class="mb-2">
                  {{ $t("Votre Commande a été annulée sur votre demande. Votre Carte Bancaire ne sera pas débitée.") }}
                  <!--                  <span class="font-weight-bold">4th October</span>-->
                </p>
              </div>
              <div
                v-if="item.id && item.status != $store.state.statuses.orders.CLOSED && item.status != $store.state.statuses.orders.CANCEL"
                class="py-2 px-5 red--text red lighten-5 text-center text-wrap rounded-pill"
              >
                <p class="mb-2">
                  {{ $t("Votre facture sera disponible une fois que tous les colis auront été validé par les vendeurs. Merci") }}
                  <!--                  <span class="font-weight-bold">4th October</span>-->
                </p>
              </div>
              <template v-if="item.id && item.status != $store.state.statuses.orders.CLOSED && item.status != $store.state.statuses.orders.CANCEL">
                <div
                  v-if="item.id && item.packagesprintable.filter((ele) => ele.status === 1).length != item.packagesprintable.length"
                  class="py-2 my-2 px-5 mb-2 primary--text primary lighten-5 text-center text-wrap rounded-pill"
                >
                  <p class="mb-2">
                    {{ $t('Certain colis de votre commande sont déjà en Transit. Pour annuler allez dans la gestion des colis') }} -
                    <router-link
                      :to="item.user_id === user.id?'/user/packages':'/seller/packages'"
                      class="font-weight-bold"
                    >
                      {{ $t("Mes colis expédiés") }}
                    </router-link>
                  </p>
                </div>
                <p class="mb-2" />
                <cancel-order
                  v-if="item.id && item.packagesprintable.filter((ele) => ele.status === 1).length == item.packagesprintable.length"
                  :item="item"
                  @removed="getOrder"
                />
              </template>

              <!--              v-if="item.id && item.status != $store.state.statuses.orders.CLOSED"-->
            </div>
          </div>
        </base-card>
      </v-col>
      <v-col
        v-for="pkg in item.packagesprintable"
        v-if="item && item.packagesprintable"
        cols="12"
      >
        <base-card>
          <div
            class="grey lighten-2 d-flex justify-space-around flex-wrap"
          >
            <div class="d-flex my-3 mx-3">
              <p class="text-14 grey--text text--darken-2 mb-0 mr-2">
                {{ $t("Numéro de colis") }}:
              </p>
              <p class="mb-0 grey--text text--darken-4">
                {{ pkg.id }}
              </p>
            </div>
            <div class="d-flex my-3 mx-3">
              <p class="text-14 grey--text text--darken-2 mb-0 mr-2">
                {{ $t("Vendeur") }}:
              </p>
              <router-link
                v-if="pkg.seller"
                :to="`/profile/${pkg.seller_id}`"
                class="mb-0 grey--text text--darken-4 text-decoration-none"
              >
                {{ pkg.seller.pseudo || '' }}
              </router-link>
            </div>
            <div class="d-flex my-3 mx-3">
              <p class="text-14 grey--text text--darken-2 mb-0 mr-2">
                {{ $t("Status") }}:
              </p>
              <p class="mb-0 grey--text text--darken-4">
                {{ ($store.state.statuses.packages.find((e) => e.status === pkg.status) || {message: "-" }).message }}
              </p>
            </div>
            <div
              v-if="pkg.status === $store.state.statuses.packageStatus.paid"
              class="d-flex my-3 mx-3"
            >
              <cancel-package
                :item="pkg"
                @removed="getOrder"
              />
            </div>
          </div>

          <div
            v-for="(orderItem, index) in pkg.itemsprintable"
            :key="index"
          >
            <div
              class="d-flex align-center justify-space-between flex-wrap  pa-4"
            >
              <!--              <div class="d-flex justify-space-between align-center flex-wrap me-4">-->
              <div class="d-flex justify-space-between  me-4">
                <v-avatar
                  tile
                  size="64"
                  class="me-4"
                >
                  <img
                    :src="orderItem.product.image || '@/assets/images/products/Groceries/2.PremiumGroceryCollection.png'"
                    alt=""
                  >
                </v-avatar>
                <div class="">
                  <div class="text-14 font-600">
                    {{ orderItem.product_name }}
                  </div>
                  <div class="text-14 grey--text text--darken-2">
                    &euro;{{ orderItem.price }} x {{ orderItem.quantity }} <span class="float-right">&euro;{{ orderItem.total }}</span>
                  </div>
                </div>
              </div>
              <p class="mb-0 grey--text text--darken-2 ">
                {{ $t("Product properties") }}:<br>
                <span v-html="orderItem.product_attribute" />
              </p>

              <!--              <v-btn
                :to="`/products/${orderItem.product.slug }`"
                text
                color="primary"
                class="text-capitalize font-weight-bold"
              >
                {{ $t("Rédiger un avis") }}
              </v-btn>-->
            </div>
            <v-divider />
          </div>
          <div
            class="grey lighten-2 d-flex justify-space-between flex-wrap"
          >
            <div class="d-flex my-3 mx-3">
              <p class="text-14 grey--text text--darken-2 mb-0 mr-2">
                {{ $t("Sous-total") }}:
              </p>
              <p class="mb-0 grey--text text--darken-4">
                &euro;{{ pkg.sub_total }}
              </p>
            </div>
            <div class="d-flex my-3 mx-3">
              <p class="text-14 grey--text text--darken-2 mb-0 mr-2">
                {{ $t("Frais de transport") }}:
              </p>
              <p class="mb-0 grey--text text--darken-4">
                &euro;{{ pkg.transporter_fee }}
              </p>
            </div>
            <div class="d-flex justify-end my-md-3 mx-0  mx-md-3 col-12 col-md-auto">
              <p class="text-14 font-weight-bold mb-0 mr-2">
                {{ $t("Total") }}:
              </p>
              <p class="mb-0 font-weight-bold font-weight-bold">
                &euro;{{ pkg.total }}
              </p>
            </div>
          </div>
        </base-card>
      </v-col>
      <v-col
        v-if="item.address"
        cols="12"
        lg="6"
      >
        <base-card>
          <div class="pa-5">
            <h4 class="mb-3 grey--text text--darken-4">
              {{ $t("Adresse de livraison") }}
            </h4>
            <p class="text-14 mb-0">
              {{ item.address.address }}, {{ item.address.city }} {{ item.address.zip }}
            </p>
          </div>
        </base-card>
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <base-card>
          <div class="pa-5">
            <h4 class="mb-3 grey--text text--darken-4">
              {{ $t("Résumé total") }}
            </h4>
            <div class="d-flex justify-space-between mb-2">
              <p class="tex-14 mb-0 grey--text text--darken-2">
                {{ $t("Sous - total") }}:
              </p>
              <p class="tex-14 mb-0 font-weight-bold">
                &euro;{{ item.package_total }}
              </p>
            </div>
            <div
              v-if="item.donate && item.user_id === user.id"
              class="d-flex justify-space-between mb-2"
            >
              <p class="tex-14 mb-0 grey--text text--darken-2">
                {{ $t("Don") }}:
              </p>
              <p class="tex-14 mb-0 font-weight-bold">
                &euro;{{ item.danate }}
              </p>
            </div>
            <div v-if="item.user_id === user.id" class="d-flex justify-space-between mb-2">
              <p class="tex-14 mb-0 grey--text text--darken-2">
                {{ $t("Frais de Sécurité") }}:
              </p>
              <p class="tex-14 mb-0 font-weight-bold">
                $&euro;{{ item.security_fee }}
              </p>
            </div>
            <!--            <div class="d-flex justify-space-between mb-2">
              <p class="tex-14 mb-0 grey&#45;&#45;text text&#45;&#45;darken-2">
                Discount:
              </p>
              <p class="tex-14 mb-0 font-weight-bold">
                -$30
              </p>
            </div>-->
            <v-divider class="my-3" />
            <div class="d-flex justify-space-between mb-2">
              <p class="tex-14 mb-0 font-weight-bold">
                {{ $t("Total") }}:
              </p>
              <p class="tex-14 mb-0 font-weight-bold">
                &euro;{{ item.total }}
              </p>
            </div>
            <!--            <p>{{ $t("Payment method") }}: Carte de crédit/débit</p>-->
          </div>
        </base-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

  import CancelPackage from '../../components/products/CancelPackage.vue'
  import CancelOrder from '../../components/products/CancelOrder.vue'
  import { mapState } from 'vuex'

  export default {
    components: {
      CancelOrder,
      CancelPackage,

    },
    data () {
      return {
        isSidebar: false,
        loading: false,
        baseUrl: process.env.VUE_APP_API || process.env.baseURL || '',
        item: {

        },
      }
    },
    computed: {
      ...mapState({
        user: 'user',
        salutations: 'salutations',
        countries: 'countries',
        displayNotification: 'display_notification',
      }),
    },
    created () {
      this.getOrder()
    },
    methods: {
      getOrder () {
        this.loading = true
        axios.get(`/orders/${this.$route.params.id}`).then((response) => {
          this.loading = false
          if (response.error || response.data.error) {

          } else {
            this.item = response.data
          }
        }).then(() => {
          this.loading = false
        })
      },
    },
  }
</script>
<style lang="postcss" scoped>
.w-32 {
  width: 32px;
}
.w-12 {
  width: 12px;
}
.order-details-custom-done {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
</style>
